const generateSizeStyles = (home: boolean, size: string, ratio: number, mobile = false) => {
  let max
  switch(size) {
    case "Large":
      // Large carousels

      // Vertical slides on the homepage are treated differently
      if(home && ratio < 1) {
        return {
          size,
          max: 100 * ratio,
          width: `calc(${100 * ratio} * var(--vh, 1vh))`,
          height: `calc(100 * var(--vh, 1vh))`,
        }
      }

      // Full width everywhere
      // 100vh on home page
      // 50vh on mobile home page
      // Intrinsic ratio on work page
      return {
        size,
        max: 100,
        width: `calc(100 * var(--vw, 1vw))`,
        height: home
          ? mobile
            ? `calc(50 * var(--vw, 1vw)`
            : `calc(100 * var(--vh, 1vh))`
          : `calc(${100 / ratio} * var(--vw, 1vw))`,
      }
    case "Small":
      // Small carousels
      // Max 400 on home page
      // Not present on work page
      max = ratio >= 1
        ? 0.3 // 400 / 1440
        : 0.15
      break
    case "Medium":
    default:
      // Medium carousels
      // Max 600 on home page
      // Max 650 on work page
      // max = home
      //   ? 600 / 1440
      //   : ratio >=1
      //     ? 0.45 // 650 / 1440
      //     : 0.7 // 550 / 796
      max = ratio >= 1
        ? 0.45 // 650 / 1440
        : 0.7 // 550 / 796
  }

  max *= 100

  // if(home) return ratio >= 1 
  //   ? {
  //     width: `${max}vw`,
  //     height: `${max / ratio}vw`,
  //   } : {
  //     width: `${max * ratio}vw`,
  //     height: `${max}vw`,
  //   }

  return ratio >= 1 
    ? {
      size,
      max,
      width: `calc(${max} * var(--vw, 1vw))`,
      height: `calc(${max / ratio} * var(--vw, 1vw))`,
    } : {
      size,
      max: max * ratio,
      width: `calc(${max * ratio} * var(--vh, 1vh))`,
      height: `calc(${max} * var(--vh, 1vh))`,
    }
}

export default generateSizeStyles
export { generateSizeStyles }