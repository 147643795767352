import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { withPrismicPreview, useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import HomePage from "../components/HomePage"

const IndexQuery = graphql`
  query IndexQuery {
    prismicSettings {
      _previewable
      data {
        image {
          gatsbyImageData
        }
        homepage {
          document {
            ... on PrismicHome {
              id
              _previewable
              data {
                body {
                  ... on PrismicHomeDataBodySlide {
                    id
                    primary {
                      caption {
                        richText
                      }
                      client {
                        document {
                          ... on PrismicClient {
                            id
                            _previewable
                            data {
                              name
                              description {
                                richText
                              }
                              url {
                                url
                              }
                            }
                          }
                        }
                      }
                      image {
                        gatsbyImageData(placeholder: BLURRED)
                        dimensions {
                          width
                          height
                        }
                      }
                      size
                      position
                    }
                    slice_type
                  }
                  ... on PrismicHomeDataBodyVideoSlide {
                    id
                    primary {
                      caption {
                        richText
                      }
                      client {
                        document {
                          ... on PrismicClient {
                            id
                            data {
                              name
                              description {
                                richText
                              }
                              url {
                                url
                              }
                            }
                            _previewable
                          }
                        }
                      }
                      embed {
                        id
                        provider_name
                        provider_url
                        type
                        title
                        width
                        height
                        embed_url
                        video_id
                        duration
                        account_type
                        author_name
                        author_url
                        description
                        html
                        is_plus
                        prismicId
                        thumbnail_height
                        thumbnail_url
                        thumbnail_url_with_play_button
                        thumbnail_width
                        uri
                        version
                      }
                      position
                      size
                    }
                    slice_type
                  }
                }
                title
                description
                image {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`

const Index = ({ data, ...rest }: PageProps) => {
  const staticData = useStaticQuery(IndexQuery),
    { data: { prismicSettings }, isPreview } = useMergePrismicPreviewData(staticData),
    homepageData = prismicSettings?.data?.homepage?.document?.data,
    sharingImageData = prismicSettings?.data?.image?.gatsbyImageData

  return <HomePage data={homepageData} sharingImageData={sharingImageData} {...rest} />
}

export default withPrismicPreview(Index)
