import React, { useContext } from "react"
import { HomepageCarousel } from "./HomepageCarousel"
import { LayoutContext } from "./layout"
import { Helmet } from "react-helmet"
import { resizeImage } from "../utilities/resizeImage"

const HomePage = ({ data, sharingImageData, ...rest }: any) => {
  const layout = useContext(LayoutContext),
    { lastHomepageIndex } = layout,
    sharingImage = sharingImageData
      ? resizeImage(sharingImageData)
      : {},
    desktopData = { body: data?.body },
    mobileData = { body: data?.body?.filter(slice => slice?.slice_type === `slide`) }

  return (
    <>
      <Helmet>
        {/* Description */}
        {data?.description && <meta name="description" content={data.description} />}
        {data?.description && <meta property="og:description" content={data.description} />}
        {data?.description && <meta name="twitter:description" content={data.description} />}
        {/* Image */}
        {sharingImage?.src !== `` && <meta name="twitter:image" content={sharingImage.src} />}
        {sharingImage?.src !== `` && <meta property="og:image" content={sharingImage.src} />}
        {sharingImage?.width && <meta property="og:image:width" content={`${sharingImage.width}`} />}
        {sharingImage?.height && <meta property="og:image:height" content={`${sharingImage.height}`} />}
      </Helmet>
      <div className="hidden sm:block">
        <HomepageCarousel
          data={desktopData}
          index={lastHomepageIndex}
          mobile={false}
        />
      </div>
      <div className="block sm:hidden">
        <HomepageCarousel
          data={mobileData}
          mobile={true}
        />
      </div>
    </>
  )
}

export default HomePage
