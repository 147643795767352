import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { CarouselPhotoSlide, CarouselVideoSlide } from "./CarouselSlide"
import { LayoutContext } from "./layout"
import { generateSizeStyles } from "../utilities/generateSizeStyles"
import { getRatio } from "../utilities/getRatio"
import { useEventListener } from "../hooks/useEventListener"
import { getAdjacentIndex } from "../utilities/getAdjacentIndex"
import { windowIfAvailable } from "../utilities/windowIfAvailable"
import { useMedia } from "../hooks/useMedia"

const generatePositionClasses = (position: string) => {
  switch(position) {
    case "Left":
      return `justify-start`
    case "Right":
      return `justify-end`
    case "Center":
    default:
      return `justify-center` 
  }
}

const HomepageCarousel = (props: {
  data: any,
  index?: number,
  mobile: boolean,
}) => {
  const {
    data,
    mobile,
  } = props

  const layout = useContext(LayoutContext),
    { 
      ready,
      setClient,
      setSlideIndex,
      // setCarouselLength
    } = layout,
    [homepageSlideIndex, setHomepageSlideIndex] = useState(props.index ?? 0),
    timeoutIdRef = useRef<number>(),
    slides = data?.body,
    adjacentIndices = [
      getAdjacentIndex(homepageSlideIndex, slides.length, -1),
      getAdjacentIndex(homepageSlideIndex, slides.length, 1)
    ],
    isMobile = useMedia(
      ["(max-width: 699px)"],
      [true],
      false
    ),
    isVisible = mobile === isMobile,
    updateIndices = () => {
      // Reset slide index
      setSlideIndex(homepageSlideIndex)
      // Update client
      setClient(slides[homepageSlideIndex]?.primary?.client?.document?.data ?? {})
      // Update carousel length
      // setCarouselLength(slides?.length)
    }

  // Mount logic
  useEffect(() => {
    if(isVisible) updateIndices()
  }, [isVisible])

  // Update layout whenever homepage changes
  useEffect(() => {
    // Break early if not visible
    if(!isVisible) return

    // console.log(`homepageSlideIndex changed, setting slideIndex: `, homepageSlideIndex)
    setSlideIndex(homepageSlideIndex)
  }, [homepageSlideIndex])

  const next = () => {
    if (slides.length === 1) return

    const newIndex = (homepageSlideIndex + 1) % slides.length

    if(mobile) {
      // Automatically navigate to work after one loop
      if(newIndex === 0) navigate(`/work`)
      // Clear existing autoplay timeout
      clearTimeout(timeoutIdRef.current)
      // Set undefined so new timeout is created
      timeoutIdRef.current = undefined
    }

    setHomepageSlideIndex(newIndex)
    setClient(slides[newIndex]?.primary?.client?.document?.data)
  }

  const previous = () => {
    if (slides.length === 1) return
    const newIndex = (homepageSlideIndex - 1) % slides.length
    if (newIndex < 0) {
      setHomepageSlideIndex(slides.length - 1)
      setClient(slides[slides.length - 1]?.primary?.client?.document?.data ?? {})
    }
    else {
      setHomepageSlideIndex(newIndex)
      setClient(slides[newIndex]?.primary?.client?.document?.data ?? {})
    }
  }

  useEventListener(`keyup`, (e) => {
    // Break early if not visible
    if(!isVisible) return

    switch(e.code) {
      case `ArrowLeft`:
        previous()
        break
      case `ArrowRight`:
        next()
        break
      default:
    }
  }, windowIfAvailable(), { passive: true })

  useEffect(() => {
    // Don’t autoplay if not ready
    if(!ready) return

    // Don’t autoplay if not mobile
    if(!mobile) return

    // Break early if not visible
    if(!isVisible) return

    // Return if timeoutId exists
    if(timeoutIdRef.current) return

    // If timeoutId undefined, do the thing
    const id = window.setTimeout(() => next(), 200)
    timeoutIdRef.current = id

    return () => {
      clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined
    }
  }, [isVisible, mobile, next, ready, timeoutIdRef.current])

  const generateMobileSlide = (slide: any, i: number) => {
    const slideRatio = getRatio(slide),
      slidePositionClasses = generatePositionClasses(`Center`),
      // slideSizeStyles = generateSizeStyles(true, slideSize, slideRatio, true),
      slideSizeStyles = generateSizeStyles(true, `Medium`, slideRatio, true),
      slideActive = homepageSlideIndex === i,
      previousActive = adjacentIndices[0] === i,
      nextActive = adjacentIndices[1] === i,
      activeProps = homepageSlideIndex === i ? { "data-active": "true" } : {}

    return (
      <div
        {...activeProps}
        className={`absolute inset-0 top-1/2 -translate-y-1/2 w-full h-1/2 flex items-center ${slidePositionClasses}`}
        key={slide.id}
      >
        <div className={`w-full min-w-max flex items-center justify-center`}>
          <div
            className="relative"
            style={slideRatio < 1
              ? {
                width: `calc(${55 * slideRatio} * var(--vw, 1vw))`,
                height: `calc(55 * var(--vw, 1vw))`,
              } : {
                width: `calc(55 * var(--vw, 1vw))`,
                height: `calc(${55 / slideRatio} * var(--vw, 1vw))`,
              }
            }
          >
            <CarouselPhotoSlide
              active={slideActive}
              adjacentActive={previousActive || nextActive}
              animateLoad={false}
              home={true}
              onScreen={true}
              performance={true}
              slideSizeStyles={slideSizeStyles}
              slide={slide}
              type={slide.slice_type}
            />
          </div>
        </div>
      </div>
    )
  }, generateDesktopSlide = (slide: any, index: number, i: number, a: number[]) => {
    const slideSize = slide?.primary?.size,
      slideRatio = getRatio(slide),
      slidePositionClasses = generatePositionClasses(slide?.primary?.position),
      slideSizeStyles = generateSizeStyles(true, slideSize, slideRatio, false),
      // Slide is active if there is only one slide, or when it is the middle of the three windowed indices
      slideActive = a.length === 1 || i === 1,
      activeProps = homepageSlideIndex === index ? { "data-active": "true" } : {}

      let slideComponent
      if(slide.slice_type === `slide`) {
        slideComponent = (
          <CarouselPhotoSlide
            active={slideActive}
            // carouselImageSizes={carouselImageSizes}
            home={true}
            performance={true}
            slide={slide}
            slideSizeStyles={slideSizeStyles}
          />
        )
      } else {
        slideComponent = (
          <CarouselVideoSlide
            active={slideActive}
            adjacentActive={!slideActive}
            // carouselDimensions={carouselDimensions}
            home={true}
            onScreen={true}
            performance={true}
            slide={slide}
          />
        )
      }

    return slideSize === `Large`
      // Large square and horizontal slides
      ? slideRatio >= 1
        ? (
          <div
            {...activeProps}
            // className={`absolute inset-0 top-0 w-full h-full flex items-center ${slidePositionClasses}`}
            className={`absolute top-0 w-full h-full`}
            style={slideSizeStyles}
            key={slide.id}
          >
            {slideComponent}
          </div>
        // Large vertical slides
        ) : (
          <div
            {...activeProps}
            className={`absolute inset-0 top-0 w-full h-full flex items-center ${slidePositionClasses}`}
            key={slide.id}
          >
            <div className={`w-1/2 min-w-max flex items-center justify-center`}>
              <div className="min-w-[50px]" />
              <div
                className="relative"
                style={slideSizeStyles}
              >
                {slideComponent}
              </div>
              <div className="min-w-[50px]" />
          </div>
        </div>
    // Small and medium slides
    ) : (
      <div
        {...activeProps}
        className={`absolute inset-0 top-0 w-full h-full flex items-center ${slidePositionClasses}`}
        key={slide.id}
      >
        <div className={`w-1/2 min-w-max flex items-center justify-center`}>
          <div className="min-w-[50px]" />
          <div
            className="relative"
            style={{
              ...slideSizeStyles,
              minWidth: slideSizeStyles.width,
            }}
          >
            {slideComponent}
          </div>
          <div className="min-w-[50px]" />
        </div>
      </div>
    )
  }

  const windowedIndices = slides.length === 1
    ? [homepageSlideIndex]
    : [adjacentIndices[0], homepageSlideIndex, adjacentIndices[1]]

  const carousel = mobile
    ? slides.map((slide, i) => generateMobileSlide(slide, i))
    : windowedIndices.map((index, i, a) => generateDesktopSlide(slides[index], index, i, a))

  return (
    <div
      className="fixed z-10 flex w-screen h-screen"
      onClick={mobile ? () => navigate(`/work`) : undefined}
    >
      <div className="touch:hidden cursor prev absolute top-0 left-0 z-10 w-1/2 h-full" data-cursor={adjacentIndices[0] + 1} onClick={previous} />
      <div className="touch:hidden cursor next absolute top-0 left-1/2 z-10 w-1/2 h-full" data-cursor={adjacentIndices[1] + 1} onClick={next} />
      {/* Carousel */}
      {carousel}
    </div>
  )
}

export default HomepageCarousel
export { HomepageCarousel }
